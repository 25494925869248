.jstree-default .jstree-anchor {
  font-size: 12px;
  color: #5b5654;
}
.jstree-default .jstree-node {
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jstree-anchor {
  display: inline;
}
.jstree-default .jstree-icon:empty {
  width: 20px;
}
.jstree-default .jstree-checkbox {
  float: right;
}
