html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: relative;
  top: 0;
}

.App::after,
.App::before {
  box-sizing: border-box;
}
/*Fix for jodit-editor, default z-index is 6 and is set as a inline style*/
.jodit-popup {
    z-index: 1600 !important
}

.react-grid-layout {
  padding-left: 24px;
}

.rootTree {
  align-items: center;
  background: #999;
  color: #252422;
  margin-bottom: 1px;
  cursor: pointer;
  padding: 4px 6px;
  font-size: 12px;
}
.rooTreeValue {
  padding: 6px 0;
}
.tree-node {
  overflow: hidden;
}

/* style for the container */
.tree-node-children {
  margin-left: 15px;
}

/* style for the leaf */
.tree-node-leaf {
  margin-bottom: 1px;
  cursor: pointer;
  background-color: #999;
  color: #252422;
  padding: 4px 6px;
  padding-left: 13px;
  font-size: 12px;
}

.active {
  background-color: transparent;
  box-shadow: inset 0 0 20px #ababab;
  border-radius: 4px;
}

.tree-value {
  padding: 6px;
  padding-left: 15px;
}

.tree-node-children-collapsed {
  height: 0px;
}

.tree-node-arrow {
  cursor: pointer;
  margin-right: 2px;
  margin-top: 2px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* rotate the triangle to close it */
.tree-node-arrow-collapsed {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/* Tree 2nd level */
.tree-node-children > .tree-node-leaf {
  padding-left: 10px;
}
.tree-node-children > .tree-node-leaf,
.tree-node-children > .tree-node > .rootTree {
  background-color: #b3b3b3;
}

/* Tree 3nd level */
.tree-node-children > .tree-node > .tree-node-children > .tree-node-leaf,
.tree-node-children
  > .tree-node
  > .tree-node-children
  > .tree-node
  > .rootTree {
  background-color: #ccc;
}

/* Tree 4th level */
.tree-node-children
  > .tree-node
  > .tree-node-children
  > .tree-node
  > .tree-node-children
  > .tree-node-leaf,
.tree-node-children
  > .tree-node
  > .tree-node-children
  > .tree-node
  > .tree-node-children
  > .tree-node
  > .rootTree {
  background-color: #e4e4e4;
}
