@media print {
  body * {
    visibility: hidden;
    display: none;
  }
  table {
    display: table !important;
  }
  thead {
    display: table-header-group !important;
  }
  tbody {
    display: table-row-group !important;
  }
  tr {
    display: table-row !important;
  }
  td,
  th {
    display: table-cell !important;
  }
}
