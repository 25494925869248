/**
 * ==============================================
 * Dot Revolution
 * ==============================================
 */

.dot-revolution-UniQueue {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 52px;
  background-color: transparent;
  color: transparent;
  animation: dot-revolution2 1.4s linear -0.7s infinite;
}

.dot-revolution {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -40px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0px -22px 0 0 #9db91000, -23px -33px 0 -3px #9db91000,
    23px -33px 0 -3px #9db91000, 0px -43px 0 -3px #9db91000,
    0px -63px 0 -5px #9db91000;
  animation: dot-revolution 1.4s linear 0.3s infinite;
}

.dot-revolution::before,
.dot-revolution::after {
  content: "";
  display: inline-block;
  position: absolute;
}

.dot-revolution::before {
  content: none !important;
}

.dot-revolution::after {
  content: none !important;
}

@keyframes dot-revolution {
  0% {
    top: -20px;
    animation-timing-function: ease-in;
    box-shadow: 0px -22px 0 -3px #9db910ff, -23px -33px 0 -3px #9db910aa,
      23px -33px 0 -3px #9db910aa, 0px -43px 0 -3px #9db910aa,
      0px -63px 0 -5px #9db910aa;
  }

  19% {
    box-shadow: 0px -22px 0 0 #9db910ff, -23px -33px 0 -3px #9db910aa,
      23px -33px 0 -3px #9db910aa, 0px -43px 0 -3px #9db910aa,
      0px -63px 0 -5px #9db910aa;
  }

  25% {
    box-shadow: 0px -22px 0 0 #9db910aa, -23px -33px 0 -5px #9db910ff,
      23px -33px 0 -5px #9db910ff, 0px -43px 0 -3px #9db910aa,
      0px -63px 0 -5px #9db910aa;
  }

  34% {
    transform: scale(1, 1);
  }

  35% {
    top: 20px;
    animation-timing-function: ease-out;
    transform: scale(1.5, 0.5);
  }

  44% {
    transform: scale(1, 1);
    box-shadow: 0px -22px 0 0 #9db910aa, -23px -33px 0 -3px #9db910ff,
      23px -33px 0 -3px #9db910ff, 0px -43px 0 -3px #9db910aa,
      0px -63px 0 -5px #9db910aa;
  }

  50% {
    box-shadow: 0px -22px 0 0 #9db910aa, -23px -33px 0 -3px #9db910aa,
      23px -33px 0 -3px #9db910aa, 0px -43px 0 -5px #9db910ff,
      0px -63px 0 -5px #9db910aa;
  }

  69% {
    box-shadow: 0px -22px 0 0 #9db910aa, -23px -33px 0 -3px #9db910aa,
      23px -33px 0 -3px #9db910aa, 0px -43px 0 -3px #9db910ff,
      0px -63px 0 -5px #9db910aa;
  }

  75% {
    box-shadow: 0px -22px 0 0 #9db910aa, -23px -33px 0 -3px #9db910aa,
      23px -33px 0 -3px #9db910aa, 0px -43px 0 -3px #9db910aa,
      0px -63px 0 -7px #9db910ff;
  }

  89% {
    top: -20px;
    box-shadow: 0px -22px 0 0 #9db910aa, -23px -33px 0 -3px #9db910aa,
      23px -33px 0 -3px #9db910aa, 0px -43px 0 -3px #9db910aa,
      0px -63px 0 -5px #9db910ff;
  }

  95% {
    box-shadow: 0px -22px 0 0 #9db910aa, -23px -33px 0 -3px #9db910aa,
      23px -33px 0 -3px #9db910aa, 0px -43px 0 -3px #9db910aa,
      0px -63px 0 -5px #9db910aa;
  }

  100% {
    top: -20px;
    box-shadow: 0px -22px 0 -3px #9db910ff, -23px -33px 0 -3px #9db910aa,
      23px -33px 0 -3px #9db910aa, 0px -43px 0 -3px #9db910aa,
      0px -63px 0 -5px #9db910aa;
  }
}

@keyframes dot-revolution2 {
  0% {
    top: -20px;
    animation-timing-function: ease-in;
  }

  10% {
    transform: scale(1, 1);
  }

  35% {
    top: 20px;
    animation-timing-function: ease-out;
    transform: scale(1.2, 0.7);
  }

  44% {
    transform: scale(1, 1);
  }

  89% {
    top: -20px;
  }

  100% {
    top: -20px;
  }
}
